export default function Header() {
  return (
    <div className="header">
        <div style={{
            background: "url(https://i0.wp.com/minhastack.com/wp-content/uploads/2020/11/fundo.jpg?fit=1080%2C1080&ssl=1)",
            padding: '1%' }}>
            <img style={{
                    width: 200,
                    margin: '1%'
                }} 
                src="https://i0.wp.com/minhastack.com/wp-content/uploads/2021/01/logoVerticalVazada.png?fit=2100%2C700&ssl=1" />
        </div>
    </div>
  );
}
